import request from '@/utils/request'
import qs from 'qs'

// 项目部
export function employmentEmployDepartment (parameter) {
  return request({
    url: '/api/back/employmentEmploy/SelectController/queryAllProjectDepartmentByEnterpriseUserId',
    method: 'get',
    params: parameter
  })
}
// 获取项目名称
export function enterpriseEmploymentManagement (parameter) {
  return request({
    url: '/api/back/employmentEmploy/SelectController/queryAllProjectFromProjectDepartment',
    method: 'get',
    params: parameter
  })
}
// 子项目名称
export function queryAllWbProject (parameter) {
  return request({
    url: '/api/back/employmentEmploy/SelectController/queryAllWbProject',
    method: 'get',
    params: parameter
  })
}
// 工种/专业
export function enterpriseEmploymentPosition (parameter) {
  return request({
    url: '/api/back/employmentEmploy/SelectController/queryAllPositionInfoByProjectIdFromDemand',
    method: 'get',
    params: parameter
  })
}
// 查询岗位评价分页（业务外包）
export function employmentEvaluatelist (parameter) {
  return request({
    url: '/api/enterprise/employmentEvaluate/enterpriseWorkerEvaluationController/queryByMy',
    method: 'get',
    params: {
      ...parameter,
      flag: 1
    }
  })
}
// 查询岗位评价分页（灵活用工）
export function queryByMy (parameter) {
  return request({
    url: '/api/enterprise/employmentEvaluate/enterpriseWorkerEvaluationController/queryByMy',
    method: 'get',
    params: {
      ...parameter,
      flag: 2
    }
  })
}
// 招聘管理--待沟通
export function waitCommunicateUserInfolist (parameter) {
  console.log(parameter)
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/queryCommunicateUserInfoList',
    method: 'get',
    params: parameter
  })
}

// 招聘管理业务外包--待签约
export function queryToBeSignLWHTUser (parameter) {
  return request({
    url: '/api/enterprise/contract/queryToBeSignLWHTUser',
    method: 'get',
    params: {
      ...parameter
    }
  })
}

// 招聘管理灵活用工--待签约
export function queryToBeSignCLXYUser (parameter) {
  return request({
    url: '/api/enterprise/contract/queryToBeSignCLXYUser',
    method: 'get',
    params: {
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      demandId: parameter.demandId,
      signStatus: parameter.signStatus
    }
  })
}

// 招聘管理灵活用工--签约作废
export function invalidation (contractId) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/invalidation/${contractId}`,
    method: 'put'
  })
}

// 招聘管理--感兴趣
export function employmentEmploylist (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/queryDemandJoinUserInfoList',
    params: parameter
  })
}
// 招聘管理--进一步沟通
export function employmentEmploycommunicate (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController',
    method: 'post',
    data: parameter
  })
}
// 招聘管理--详情
export function employmentEmployDetail (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/queryUserInfoByUserId',
    method: 'get',
    params: parameter
  })
}

// 招聘管理--录用
export function employmentEmploy (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController',
    method: 'put',
    data: parameter
  })
}

// 招聘管理--移除
export function employmentEmployDelete (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController',
    method: 'DELETE',
    params: parameter
  })
}
// 招聘管理查询已发需求列表
export function employmentEmployPage (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/page',
    method: 'get',
    params: parameter
  })
}
// 招聘管理-停止招聘
export function employmentEmployStops (demandId) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/stops/${demandId}`,
    method: 'PUT'
  })
}
// 招聘管理--驳回  考勤管理-驳回
export function employmentEmployReject (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/reject',
    method: 'PUT',
    data: parameter
  })
}
// 智能推荐
export function employmentEmploySmart (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/smart',
    method: 'get',
    params: parameter
  })
}

// 考勤管理-列表（支持单一查询）
export function employmentEmployList (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/list',
    method: 'get',
    params: parameter
  })
}

// 考勤管理-列表
// export function employmentEmployList (parameter) {
//   return request({
//     url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/list',
//     method: 'get',
//     params: parameter
//   })
// }

// 考勤管理-明细
export function employmentEmployInfo (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/info',
    method: 'get',
    params: parameter
  })
}

// 考勤管理-消息群发
export function employmentEmployMessaging (parameter) {
  return request({
    url: '/api/enterprise/employmentMessage/enterpriseMessageController/addList',
    method: 'post',
    data: parameter
  })
}

// 考勤管理-任务终止
export function employmentEmployStop (parameter) {
  return request.put('/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/stop', parameter)
}

// 评价管理-评价详情
export function getEvaluationDetail (id) {
  return request({
    url: `/api/enterprise/employmentEvaluate/enterpriseEmployeeEvaluationController/${id}`,
    method: 'get'
  })
}

// 评价管理-查询标签详情
export function getTypeDetail () {
  return request({
    url: `/api/enterprise/employmentSupport/enterpriseDictDetailController/query`,
    method: 'get'
  })
}

// 评价管理（业务外包）-修改评价
export function enterpriseEmployeeEvaluationController (data) {
  return request({
    url: '/api/enterprise/employmentEvaluate/enterpriseWorkerEvaluationController',
    method: 'post',
    data
  })
}

// 佣金管理-列表查询
export function getCommissionList (data) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSalaryManagementController/queryList',
    method: 'get',
    params: data
  })
}

// 佣金管理-新增工资条
export function addCommission (data) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSalaryManagementController/add',
    method: 'post',
    data
  })
}
// 薪资发放历史详情
export function queryHistory (data) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSalaryManagementController/queryHistory',
    method: 'get',
    params: data
  })
}
// 批量上传多条薪资
export function addBatchCommission (data) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSalaryManagementController/addBatch',
    method: 'post',
    data
  })
}

/**
 * 企业端-岗位用工管理-岗位佣金管理-导出佣金模板
 * @param {*} data
 * @returns
 */

export function exportSalaryTemplate (data) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSalaryManagementController/exportSalaryTemplate',
    data,
    method: 'post',
    responseType: 'blob'
  }).then((response) => response)
}

// 列表-灵活用工
export function queryUserInfoByPaging (model) {
  return request({
    // url: '/api/enterprise/employmentEmploy/enterpriseIndexController/queryUserInfoByPaging',
    url: 'api/app/employmentEmploy/appIndexController/pageByPosition',
    method: 'get',
    params: {
      ...model
    }
  })
}

// 列表-找人
export function queryUserData (model) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseIndexController/queryUserInfoByPaging',
    method: 'get',
    params: {
      ...model
    }
  })
}
// 导入业务外包薪资
export function importByExcel (data, flag) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseSalaryManagementController/importByExcel/${flag}`,
    method: 'POST',
    data
  })
}

/**
 * 灵活用工招聘管理-待沟通-确认录用
 * @param {*} data
 * @returns
 */
export function enterpriseEmploymentManagementController (data) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController',
    method: 'put',
    data
  })
}

/**
 * 企业端-编辑用工需求前的查询-查询用工需求详情
 * @param {*} id
 * @returns
 */
export function getById (id) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterprisePublishDemandController/getById/${id}`,
    method: 'get'
  })
}

/**
 * 企业端-编辑用工需求前的查询-查询用工需求详情
 * @param {*} data
 * @returns
 */
export function publishDemand (data) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterprisePublishDemandController/publishDemand`,
    method: 'post',
    data
  })
}

/**
 * 岗位佣金管理列表、平台工资支付管理
 * @param {*} params
 * @returns
 */
export function querySm (params) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseSalaryManagementController/querySm`,
    method: 'get',
    params
  })
}

/**
 * 岗位佣金管理列表、平台工资支付管理- 查看明细
 * @param {*} data
 * @returns
 */
export function pageSalaryInfo (params) {
  return request({
    url: `/api/plat/employmentEmploy/platSalaryController/pageSalaryInfo`,
    method: 'get',
    params
  })
}

/**
 * 更新分组
 * @param {*} data
 * @returns
 */
export function updateSalary (data) {
  return request({
    url: `/api/salary/SalaryGroupController`,
    method: 'put',
    data
  })
}

/**
 * 条件查询一条符合条件的服务费减免申请数据
 * @param {*} params
 * @returns
 */
export function queryOneByCondition (params) {
  return request({
    url: `/api/salary/DeAplayController/queryOneByCondition`,
    method: 'get',
    params
  })
}

/**
 * 新增服务费减免申请
 * @param {*} data
 * @returns
 */
export function addApply (data) {
  return request({
    url: `/api/salary/DeAplayController`,
    method: 'post',
    data
  })
}

/**
 * 根据ID更新服务费减免申请
 * @param {*} data
 * @returns
 */
export function updateApply (data) {
  return request({
    url: `/api/salary/DeAplayController`,
    method: 'put',
    data
  })
}

/**
 * 工资退款
 * @param {*} params
 * @returns
 */
export function salaryRefund (ids) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseSalaryManagementController/refund`,
    method: 'put',
    params: {
      id: ids
    }
  })
}

/**
 * 工资条重新上传
 * @param {*} params
 * @returns
 */
export function salaryReload (ids) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseSalaryManagementController/reValidation`,
    method: 'put',
    params: {
      id: ids
    }
  })
}

/**
 * 获取企业发薪合同
 * @param {*} enterpriseId
 * @returns
 */
export function getSalaryContract (enterpriseId) {
  return request({
    url: `/api/enterprise/contract/checkAndCreateSalaryPayContract/${enterpriseId}`,
    method: 'post'
  })
}

/**
 * 校验发薪人员是否正常
 * @param {*} enterpriseId
 * @returns
 */
export function checkUserIsCanPay (data) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseSalaryManagementController/checkUserIsCanPay`,
    method: 'post',
    data
  })
}
